import { Box, createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { LocaleProvider, LOCALES } from './lang';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import FailPage from './components/pages/portalcaptive/Fail';
import SuccessPage from './components/pages/portalcaptive/Success';
import Register from './components/pages/portalcaptive/Register/Register';
import PreRegistro from './components/pages/portalcaptive/pre-registro/PreRegistro';
import PreLogIn from './components/pages/portalcaptive/pre-LogIn/PreLogIn';
import LandingPage from './components/pages/portalcaptive/LandingPage/LandingPage';
import { GetAllSettings, ISettingsUrl, UpdateSettings, GetSelectedBanner } from './services';


const defaultColor = process.env.REACT_APP_DEFAULT_COLOR || '#ffffff';
const primaryColor = process.env.REACT_APP_PRIMARY_COLOR || defaultColor;
const primaryColorHover = process.env.REACT_APP_PRIMARY_COLOR_HOVER || defaultColor;

const theme = createTheme({
	palette: {
		primary: {
			main: '#ffffff',
		},
		secondary: {
			main: '#3D496E',
		}
	},
	typography: {
		fontFamily: [
			'NunitoSans',
			'sans-serif',
			'Poppins',
			'Poppins-Thin'
		].join(','),
	},
});


function createData(id: string, banner_type: string, file_url: string, file_encoded: string, splash_page: boolean, pre_login: boolean, pre_registro: boolean, landing_page: boolean, redirect_url: string, weight: number) {
	if (!(redirect_url.includes("http://") || redirect_url.includes("https://"))) {
		redirect_url = "http://" + redirect_url;
	}
	return { id, banner_type, file_url, file_encoded, splash_page, pre_login, pre_registro, landing_page, redirect_url, weight };
}

function App() {

	type cover = {
		id: string;
		title: string;
		description: String;
		language: string;
		file_encoded: string;
	};

	const [currentLocale, setCurrentLocale] = useState<string>(LOCALES.ENGLISH)
	const [width, setWidth] = useState<number>(window.innerWidth);
	const [title, setTitle] = useState<string>('');
	const [description, setDescription] = useState<string>('');
	const [titleRegister, setTitleRegister] = useState<string>('');
	const [file, setFile] = useState<string>('');
	const [showPreRegistro, setShowPreRegistro] = useState(false);
	const [titleAllLanguage, setTitleAllLanguages] = useState([]);
	//Banners
	const [banners, setBanners] = React.useState<{ id: string; file_url: string; file_encoded: string; splash_page: boolean; pre_login: boolean; pre_registro: boolean; landing_page: boolean; redirect_url: string; weight: number; }[]>([]);
	const [bannersSplash, setBannersSplash] = React.useState<{ id: string; file_url: string; file_encoded: string; splash_page: boolean; pre_login: boolean; pre_registro: boolean; landing_page: boolean; redirect_url: string; weight: number; }[]>([]);
	const [bannersLogin, setBannersLogin] = React.useState<{ id: string; file_url: string; file_encoded: string; splash_page: boolean; pre_login: boolean; pre_registro: boolean; landing_page: boolean; redirect_url: string; weight: number; }[]>([]);
	const [bannersPreregistro, setBannersPreregistro] = React.useState<{ id: string; file_url: string; file_encoded: string; splash_page: boolean; pre_login: boolean; pre_registro: boolean; landing_page: boolean; redirect_url: string; weight: number; }[]>([]);
	const [bannersLanding, setBannersLanding] = React.useState<{ id: string; file_url: string; file_encoded: string; splash_page: boolean; pre_login: boolean; pre_registro: boolean; landing_page: boolean; redirect_url: string; weight: number; }[]>([]);
	const [currentIndex, setCurrentIndex] = useState<number>(0);
	const [currentIndexLogin, setCurrentIndexLogin] = useState<number>(0);
	const [defaultImage, setDefaultImage] = useState<string>('');

	const [activateRegister, setActivateRegister] = useState(true);

	const handleLocaleChange = (locale: string) => {
		setCurrentLocale(locale);
	}

	const changeLenguage = (lenguage: string) => {

		switch (lenguage) {
			case 'es-es':
				const spanishLanguage: cover = titleAllLanguage.filter((r: any) => r.language === "spanish")[0];
				if (spanishLanguage && spanishLanguage?.file_encoded) {
					setFile(spanishLanguage.file_encoded);
				}
				break;
			case 'pt-pt':
				const portugueseLanguage: cover = titleAllLanguage.filter((r: any) => r.language === "portuguese")[0];
				if (portugueseLanguage && portugueseLanguage?.file_encoded) {
					setFile(portugueseLanguage.file_encoded);
				}
				break;
			case 'en-us':
				const englishLanguage: cover = titleAllLanguage.filter((r: any) => r.language === "english")[0];
				if (englishLanguage && englishLanguage?.file_encoded) {
					setFile(englishLanguage.file_encoded);
				}
				break;
		}

	}

	useEffect(() => {

		const timeout = setTimeout(() => {
			setShowPreRegistro(true);
		}, 2000); // Mostrar el componente después de 2 segundos

		return () => clearTimeout(timeout);
	}, []);

	function handleWindowSizeChange() {
		setWidth(window.innerWidth);
	}

	const nextSlide = () => {
		if (window.location.pathname === '/login') {
			if (isNaN(currentIndexLogin)) {
				setCurrentIndexLogin(0);
			} else {
				setCurrentIndexLogin((prevIndex) => (prevIndex + 1) % bannersLogin.length);
			}
		}

		if (window.location.pathname === '/') {
			if (isNaN(currentIndex)) {
				setCurrentIndex(0);
			} else {
				const aux = currentIndex + 1 > bannersPreregistro.length ? currentIndex + 1 : 0;
				setCurrentIndex(aux);
			}
		}
	};

	useLayoutEffect(() => {
		const getAll = async () => {
			const settings: any[] = await GetAllSettings();

			if (settings && settings.length > 0) {
				setDefaultImage(settings[0].file_encoded);
				const res = settings[0].covers ? settings[0].covers : [];

				if (!(settings[0].form_login && settings[0].form_login.password)
					|| settings[0].invite_mode) {
					setActivateRegister(false);
				}

				if (res) {
					setTitleAllLanguages(res);

					const spanishLanguage = { ...res.filter((r: any) => r.language === "spanish")[0] };
					const englishLanguage = { ...res.filter((r: any) => r.language === "english")[0] };
					const portugueseLanguage = { ...res.filter((r: any) => r.language === "portuguese")[0] };

					switch (window.navigator.language) {
						case 'es-ES':
							setCurrentLocale(LOCALES.SPANISH)
							setTitle(spanishLanguage.title);
							setDescription(spanishLanguage.description);
							setTitleRegister("Registro");
							break;
						case 'pt-PT':
							setCurrentLocale(LOCALES.PORTUGUESE)
							setTitle(portugueseLanguage.title);
							setDescription(portugueseLanguage.description);
							setTitleRegister("Registo");
							break;
						case 'en-GB':
							setCurrentLocale(LOCALES.ENGLISH)
							setTitle(englishLanguage.title);
							setDescription(englishLanguage.description);
							setTitleRegister("Register");
							break;
						case 'en-US':
							setCurrentLocale(LOCALES.ENGLISH)
							setTitle(englishLanguage.title);
							setDescription(englishLanguage.description);
							setTitleRegister("Register");
							break;
						default:
							setCurrentLocale(LOCALES.ENGLISH)
							setTitle(englishLanguage.title);
							setDescription(englishLanguage.description);
							break;
					}
				}
			}
		}

		getAll();

		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		}
	}, []);

	useLayoutEffect(() => {
		const getBanners = async () => {
			let res = await GetSelectedBanner();
			if (res) {
				//let aux: { id: string; file_url: string; file_encoded:string; splash_page:boolean; pre_login:boolean; pre_registro:boolean; landing_page:boolean; redirect_url: string; weight:number; }[] = [];
				let auxSplash: { id: string; banner_type: string; file_url: string; file_encoded: string; splash_page: boolean; pre_login: boolean; pre_registro: boolean; landing_page: boolean; redirect_url: string; weight: number; }[] = [];
				let auxLogin: { id: string; banner_type: string; file_url: string; file_encoded: string; splash_page: boolean; pre_login: boolean; pre_registro: boolean; landing_page: boolean; redirect_url: string; weight: number; }[] = [];
				let auxPreregistro: { id: string; banner_type: string; file_url: string; file_encoded: string; splash_page: boolean; pre_login: boolean; pre_registro: boolean; landing_page: boolean; redirect_url: string; weight: number; }[] = [];
				let auxLanding: { id: string; banner_type: string; file_url: string; file_encoded: string; splash_page: boolean; pre_login: boolean; pre_registro: boolean; landing_page: boolean; redirect_url: string; weight: number; }[] = [];

				//aux.push(createData(element.id, element.file_url, element.file_encoded, element.splash_page, element.pre_login, element.pre_registro, element.landing_page, element.redirect_url, element.weight));
				if (res.splash_page) {
					auxSplash.push(createData(res.id, res.banner_type, res.file_url, res.file_encoded, res.splash_page, res.pre_login, res.pre_registro, res.landing_page, res.redirect_url, res.weight));
				}
				if (res.pre_registro) {
					auxPreregistro.push(createData(res.id, res.banner_type, res.file_url, res.file_encoded, res.splash_page, res.pre_login, res.pre_registro, res.landing_page, res.redirect_url, res.weight));
				}
				if (res.pre_login) {
					auxLogin.push(createData(res.id, res.banner_type, res.file_url, res.file_encoded, res.splash_page, res.pre_login, res.pre_registro, res.landing_page, res.redirect_url, res.weight));
				}
				if (res.landing_page) {
					auxLanding.push(createData(res.id, res.banner_type, res.file_url, res.file_encoded, res.splash_page, res.pre_login, res.pre_registro, res.landing_page, res.redirect_url, res.weight));
				}

				//setBanners(aux);
				setBannersSplash(auxSplash);
				setBannersLogin(auxLogin);
				setBannersPreregistro(auxPreregistro);
				setBannersLanding(auxLanding);
			}
		}

		getBanners();
	}, []);

	const isMobile = width <= 768;

	const [show, setShow] = React.useState(false);
	const [snackbarOpen, setSnackbarOpen] = React.useState(false);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [success, setSuccess] = useState<boolean>(true);
	const [id, setId] = useState<string>('');
	const [url_landing, setUrl_landing] = useState<string>('');

	const handleLinkChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newValue = event.target.value;

		if (newValue.startsWith('http://') || newValue.startsWith('https://')) {
			setUrl_landing(newValue);
		} else {

			setUrl_landing('http://' + newValue);
		}
	};

	useLayoutEffect(() => {
		const getAll = async () => {
			const settingsData: ISettingsUrl[] = await GetAllSettings();
			if (settingsData && settingsData.length > 0) {
				const firstSettings = settingsData[0];
				setId(firstSettings.id);
				setUrl_landing(firstSettings.url_landing);
			}
			setIsLoading(false);
		};
		getAll();
	}, []);


	const handleSnackbarClick = async (event: any) => {
		if (id) {
			const formData = new FormData();
			formData.append('url_landing', url_landing);
			const response = await UpdateSettings(formData, id);
			setSuccess(true);
			setSnackbarOpen(true);
		} else {
			console.error("Invalid ID");
		}
	};



	return (
		<LocaleProvider locale={currentLocale}>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<BrowserRouter>
					<Routes>
						<Route path='/www/coova.html' />
						<Route
							path="/"
							element={
								<Box sx={{ display: 'flex', flexDirection: `${isMobile ? 'column' : 'row'}` }}>
									{showPreRegistro && activateRegister && (
										<PreRegistro
											isMobile={isMobile}
											setLocale={handleLocaleChange}
											currentLocale={currentLocale}
											allTitle={titleAllLanguage}
											defaultTitleLanguage={title}
											defaultDescriptionLanguage={description}
											changeLenguage={changeLenguage}
											banners={bannersPreregistro}
											bannersSplash={bannersSplash} />
									)}
									{showPreRegistro && !activateRegister && (
										<PreLogIn
											isMobile={isMobile}
											allTitle={titleAllLanguage}
											defaultTitleLanguage={title}
											setLocale={handleLocaleChange}
											currentLocale={currentLocale}
											changeLenguage={changeLenguage}
											banners={bannersLogin}
										/>)}
								</Box>
							}
						/>
						<Route path='/register' element={
							<Box sx={{ display: 'flex', flexDirection: `${isMobile ? 'column' : 'row'}` }}>
								{activateRegister && <Register
									isMobile={isMobile}
									title={titleRegister}
									setLocale={handleLocaleChange}
									currentLocale={currentLocale}
									changeLenguage={changeLenguage}
									banners={bannersPreregistro}
								/>}
								{!activateRegister && <PreLogIn
									isMobile={isMobile}
									allTitle={titleAllLanguage}
									defaultTitleLanguage={title}
									setLocale={handleLocaleChange}
									currentLocale={currentLocale}
									changeLenguage={changeLenguage}
									banners={bannersLogin}
								/>}
							</Box>
						}
						/>
						<Route path='/login' element={
							<Box sx={{ display: 'flex', flexDirection: `${isMobile ? 'column' : 'row'}` }}>
								<PreLogIn
									isMobile={isMobile}
									allTitle={titleAllLanguage}
									defaultTitleLanguage={title}
									setLocale={handleLocaleChange}
									currentLocale={currentLocale}
									changeLenguage={changeLenguage}
									banners={bannersLogin}
								/>
							</Box>
						}
						/>
						<Route path='/landing' element={
							<Box sx={{ display: 'flex', flexDirection: `${isMobile ? 'column' : 'row'}` }}>
								<LandingPage
									banners={bannersLanding} />
							</Box>
						}
						/>
						<Route path='/success' element={
							<Box sx={{ display: 'flex', flexDirection: `${isMobile ? 'column' : 'row'}` }}>
								<SuccessPage isMobile={isMobile} url_landing={url_landing} navigate={Navigate} />
							</Box>
						}
						/>
						<Route path='/fail' element={
							<Box sx={{ display: 'flex', flexDirection: `${isMobile ? 'column' : 'row'}` }}>
								<FailPage isMobile={isMobile} url_landing={url_landing} />
							</Box>
						}
						/>
					</Routes>
				</BrowserRouter>
			</ThemeProvider>
		</LocaleProvider>
	);
}

export default App;
