import React from 'react';

const FloatingImageWhite = ({isMobile}: {isMobile?: boolean}) => {
    return (
        <div style={{ position: 'absolute', margin: '0px', zIndex: 999, right: '-5px', bottom: '-10px' }}>
            <img src={require("./image/wizzieLogoBlanco.png")} alt="Logo" style={{ padding: '0px', height: isMobile ? '50px' : '75px' }} />
        </div>
    );
}

export default FloatingImageWhite;
