import axios from "axios"
import { API_BACKOFFICE_URL } from "../constants/api";

export interface ISettingsUrl {
    id: string;
    url_landing: string;
}
export interface ICoverLanguage {
    id: string;
    title: string;
    description: string;
    language: "spanish" | "english" | "portuguese";
    file_enconded: string;
}

export const GetAllSettings = async () => {
    try {
        const response = await axios.get(`${API_BACKOFFICE_URL}/admin/settings`);

        return response.data;
    } catch (err) {
        console.error(err);
    }
}

export const UpdateSettings = async (formData: FormData, id: string) => {
    try {
        const response = await axios.put(`${API_BACKOFFICE_URL}/admin/settings/url/${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }
        );

        return response;
    } catch (err) {
        console.error(err);
    }
}

export const GetSelectedBanner = async () => {
    try {
        const response = await axios.get(`${API_BACKOFFICE_URL}/banner/select`);

        return response.data;
    } catch (err) {
        console.error(err);
    }
}

export const updateIncrementClicksBanner = async (id: string) => {
    try {
        const response = await axios.put(`${API_BACKOFFICE_URL}/banner/update/incrementeclick/${id}`, {

        });

        return response;
    } catch (err) {
        console.error(err);
    }
}

export const updateIncrementRejectBanner = async (id: string) => {
    try {
        const response = await axios.put(`${API_BACKOFFICE_URL}/banner/update/incrementereject/${id}`, {

        });

        return response;
    } catch (err) {
        console.error(err);
    }
}