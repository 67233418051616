import { Container, Box, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import FloatingImageWhite from '../../../FloatingImageWhite';
import { GetAllSettings } from '../../../../services';

interface ILandingPage {
    isMobile?: boolean;
    banners: any[];
}

const FailPage = (props: any, { isMobile, banners }: ILandingPage) => {

    const [defaultImage, setDefaultImage] = useState<string>('');

    useEffect(() => {
        const getAll = async () => {
            const settings: any[] = await GetAllSettings();
            if (settings && settings.length > 0) {
                if (settings[0].file_encoded) {
                    setDefaultImage(settings[0].file_encoded);
                }
            }
        };

        getAll();
    }, []);

    const defaultColor = process.env.REACT_APP_DEFAULT_COLOR || '#ffffff';
    const primaryColor = process.env.REACT_APP_PRIMARY_COLOR || defaultColor;
    const primaryColorHover = process.env.REACT_APP_PRIMARY_COLOR_HOVER || defaultColor;

    return (
        <>
            <a style={{ backgroundColor: 'white', height: `${isMobile ? 'auto' : '100vh'}`, width: '100%' }}>
                <img
                    style={{
                        backgroundColor: 'white',
                        height: `${isMobile ? 'auto' : '100vh'}`,
                        width: "100%",
                        backgroundSize: "contain",
                        objectFit: "contain",
                    }}
                    src={(`data:image/jpeg;base64,${defaultImage}`)}
                    alt="logo"
                />
            </a>
            <Container component="main" style={{ height: `${props.isMobile ? 'auto' : '100vh'}`, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: primaryColor }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        color: 'white'
                    }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" style={{ width: '10rem' }} fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#fff" className="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>

                    <Typography component="h1" variant="h5" sx={{ textAlign: 'center' }}>
                        Error al ingresar
                    </Typography>
                </Box>
            </Container>
            <FloatingImageWhite />
        </>
    )
}

export default FailPage