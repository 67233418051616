import React, { useEffect, useState } from 'react'
import { Box, Container, Typography } from '@mui/material';
import { GetAllSettings, GetSelectedBanner, updateIncrementClicksBanner } from '../../../../services';
import './styles.css';
import FloatingImageWhite from '../../../FloatingImageWhite';
interface ILandingPage {
    isMobile?: boolean;
    banners: any[];
}

const LandingPage = ({ isMobile, banners }: ILandingPage) => {

    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const [defaultImage, setDefaultImage] = useState<string>('');
    const [video, setVideo] = useState<boolean>(false);

    useEffect(() => {
        if (!banners || banners.length == 0) {
            const getAll = async () => {
                let res = await GetSelectedBanner();
                if (res && res.landing_page) {
                    if (res.banner_type != "imagen") {
                        setVideo(true);
                    }
                    banners.push(res);
                } else {
                    const settings: any[] = await GetAllSettings();
                    if (settings && settings.length > 0) {
                        if (settings[0].file_encoded) {
                            setDefaultImage(settings[0].file_encoded);
                        }
                    }
                }
            }
            getAll();
        } else {
            if (banners[currentIndex].banner_type != "imagen") {
                setVideo(true);
            }
        }

    }, [banners]);

    useEffect(() => {
        let time = 5000;
        if (banners && banners.length > 0) {
            time = banners[currentIndex].weight ? banners[currentIndex].weight * 100 : 5000;
        }
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % banners.length);
        }, time); // Cambia el tiempo en milisegundos según lo necesario

        return () => {
            clearInterval(interval);
        }
    }, [currentIndex, banners]);

    const [bannerClicked, setBannerClicked] = useState(false);

    const handleBannerClick = async () => {
        try {
            const bannerId = banners[currentIndex]?.id;
            if (bannerId) {
                const clicks = await updateIncrementClicksBanner(bannerId);
                setBannerClicked(true);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const defaultColor = process.env.REACT_APP_DEFAULT_COLOR || '#ffffff';
    const primaryColor = process.env.REACT_APP_PRIMARY_COLOR || defaultColor;
    const primaryColorHover = process.env.REACT_APP_PRIMARY_COLOR_HOVER || defaultColor;

    return (
        <>
            {video && (
                <a href={banners[currentIndex]?.redirect_url} target="_blank" style={{ backgroundColor: 'white', height: `${isMobile ? 'auto' : '100vh'}`, width: '100%' }}>
                    <video loop>
                        <source src={banners[currentIndex]?.file_url} type="video/mp4" style={{
                            backgroundSize: "contain",
                        }} />
                        Tu navegador no admite la reproducción de videos.
                    </video>
                </a>
            )}
            {!video && (defaultImage || banners[currentIndex]?.file_url) && (
                <a href={banners[currentIndex]?.redirect_url} target="_blank" style={{ backgroundColor: 'white', height: `${isMobile ? 'auto' : '100vh'}`, width: '100%' }}>
                    <img
                        style={{
                            backgroundColor: 'white',
                            height: `${isMobile ? 'auto' : '100vh'}`,
                            width: "100%",
                            backgroundSize: "contain",
                            objectFit: "contain",
                        }}
                        src={banners[currentIndex]?.file_url ? `${banners[currentIndex].file_url}` : (`data:image/jpeg;base64,${defaultImage}`)}
                        alt="logo"
                    />
                </a>
            )}
            <Container component="main" style={{ height: `${isMobile ? 'auto' : '100vh'}`, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: primaryColor }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        color: 'white'
                    }}
                >
                    {window.location.pathname === '/login' && (
                        <a href={banners[currentIndex]?.redirect_url}
                            target="_blank"
                            onClick={handleBannerClick}
                            style={{ backgroundColor: 'white', height: `${isMobile ? 'auto' : '100vh'}`, width: '100%' }}>
                            <img
                                style={{
                                    backgroundColor: 'white',
                                    height: `${isMobile ? 'auto' : '100vh'}`,
                                    width: "100%",
                                    backgroundSize: "contain",
                                    objectFit: "contain",
                                }}
                                src={banners[currentIndex]?.file_url ? `${banners[currentIndex].file_url}` : (`data:image/jpeg;base64,${defaultImage}`)}
                                alt="logo"
                            />
                        </a>
                    )}

                    <Typography component="h1" variant="h3" sx={{ textAlign: 'center' }}>
                        {"Bienvenidos a la "}
                    </Typography>
                    <Typography component="h2" variant="h2" sx={{ textAlign: 'center' }}>
                        {"LANDING PAGE"}
                    </Typography>
                </Box>
            </Container>
            <FloatingImageWhite />
        </>
    )
}

export default LandingPage;