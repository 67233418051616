import { Container, Box, Typography, TextField, Button, styled } from '@mui/material';
import React, { useEffect, useState } from 'react';
import WifiIcon from '@mui/icons-material/Wifi';
import { FormattedMessage } from 'react-intl';
import { loginWithEmail } from '../../../../services/user';
import { useFormik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { NavLink, useNavigate } from 'react-router-dom';
import LanguageSelector from './LanguageSelector';
import { LOCALES } from '../../../../lang';
import { GetAllSettings, GetSelectedBanner } from '../../../../services';
import { updateIncrementClicksBanner, updateIncrementRejectBanner } from '../../../../services';
import FloatingImageWhite from '../../../FloatingImageWhite';

const LogInSchema = Yup.object().shape({
    email: Yup.string().email().required(),
    name: Yup.string()
        .required()
        .min(1),
    acceptsTermsConditions: Yup.bool().required().isTrue(),
});

const TextInput = styled(TextField)(() => ({
    '& fieldset': {
        borderRadius: '1.25rem',
        borderColor: 'white',
        '& legend': {
            '& span': {
                color: 'white'
            }
        }
    },
}));

interface ILogInForm {
    email: string;
    name: string;
    acceptsTermsConditions: boolean;
    password: string;

}

interface ILogIn {
    isMobile?: boolean;
    currentLocale: string;
    setLocale: (locale: string) => void;
    defaultTitleLanguage: string;
    defaultDescriptionLanguage: string;
    allTitle: any[];
    changeLenguage: (locale: string) => void;
    banners: any[];
    bannersSplash: any[];
}

const PreRegistro = ({ isMobile, currentLocale, setLocale, defaultTitleLanguage, defaultDescriptionLanguage, allTitle, changeLenguage, banners, bannersSplash }: ILogIn) => {

    const [selectedLanguage, setSelectedLanguage] = useState<string>('');
    const [time, setTime] = useState<number>(1000);

    const [titleAllLanguage, setTitleAllLanguages] = useState(allTitle);
    const [title, setTitle] = useState(defaultTitleLanguage);
    const [description, setDescription] = useState(defaultDescriptionLanguage);
    const [currentIndex, setCurrentIndex] = useState<number>(0);

    const spanishLanguage = { ...titleAllLanguage.filter((r: any) => r.language === "spanish")[0] };
    const englishLanguage = { ...titleAllLanguage.filter((r: any) => r.language === "english")[0] };
    const portugueseLanguage = { ...titleAllLanguage.filter((r: any) => r.language === "portuguese")[0] }
    const [defaultImage, setDefaultImage] = useState<string>('');
    const [splash, setSplash] = useState<boolean>(true);
    const [video, setVideo] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const [activeRegister, setActiveRegister] = useState<boolean>(true);

    const [autoRegisterEnabled, setAutoRegisterEnabled] = useState<boolean>(false);

    const changeTitlesLanguage = (language: string) => {
        switch (language) {
            case LOCALES.SPANISH:
                setTitle(spanishLanguage.title)
                setDescription(spanishLanguage.description)
                break;
            case LOCALES.ENGLISH:
                setTitle(englishLanguage.title)
                setDescription(englishLanguage.description)
                break;
            case LOCALES.PORTUGUESE:
                setTitle(portugueseLanguage.title)
                setDescription(portugueseLanguage.description)
                break;
            default:
                setTitle(spanishLanguage.title)
                setDescription(spanishLanguage.description)
                break;
        }
    }

    const handleLanguageChange = (language: string) => {
        changeLenguage(language);
        setSelectedLanguage(language);
        setLocale(language);
        changeTitlesLanguage(language);
    };

    const navigate = useNavigate();
    const handleSubmit = async (values: ILogInForm, formikHelpers: FormikHelpers<any>) => {
        const res = await loginWithEmail({ email: values.email, name: values.name, password: values.password, method: 'register' });
        if (res)
            navigate(res ? '/success' : '/fail');
    };

    const onChange = (event: React.ChangeEvent<any>) => {
        event.persist();
        formik.handleChange(event);
        formik.setFieldTouched(event.target.name, true, false);
    };

    const handleSnackbarLoginClick = () => {
        //window.location.href = `/login`;
    }

    const handleSnackbarRegisterClick = () => {
        // window.location.href = `/register`;
    }

    const formik = useFormik(
        {
            initialValues: {
                email: '',
                name: '',
                password: '',
                acceptsTermsConditions: false
            },
            validationSchema: LogInSchema,
            onSubmit: handleSubmit
        }
    );

    const [splashDuration, setSplashDuration] = useState(3000);

    useEffect(() => {
        const fetchSelectedBanner = async () => {
            const banner = await GetSelectedBanner();
            if (banner && banner.display_seconds) {
                setSplashDuration(banner.display_seconds * 1000);
            }
        };

        fetchSelectedBanner();
        return () => { }; // Limpieza del efecto
    }, []);


    useEffect(() => {
        const timeout = setTimeout(() => {
            setSplash(false);
        }, splashDuration);

        return () => clearTimeout(timeout);
    }, [splashDuration]);



    useEffect(() => {
        if (!banners || banners.length == 0) {
            const getAll = async () => {
                let res = await GetSelectedBanner();
                if (res && res.splash_page) {
                    if (res.banner_type != "imagen") {
                        setVideo(true);
                    }
                    bannersSplash.push(res);
                }
                if (res && res.pre_registro) {
                    if (res.banner_type != "imagen") {
                        setVideo(true);
                    }
                    banners.push(res);
                }
            }
            getAll();
        } else {
            if (banners[currentIndex].banner_type != "imagen") {
                setVideo(true);
            }
        }
        const getSettings = async () => {
            const settings: any[] = await GetAllSettings();
            if (settings && settings.length > 0) {
                if (settings[0].file_encoded) {
                    setDefaultImage(settings[0].file_encoded);
                }

                if (settings[0].form_login) {
                    if (settings[0].invite_mode || !settings[0].form_login.password) {
                        setActiveRegister(false);
                    }
                }

                setAutoRegisterEnabled(settings[0].auto_register);

            }
            setIsLoading(false);
        };
        getSettings();
        return () => { };
    }, [banners, bannersSplash]);

    useEffect(() => {
        let time = 5000;
        if (banners && banners.length > 0) {
            time = banners[currentIndex].weight ? banners[currentIndex].weight * 100 : 5000;
        }
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % banners.length);
        }, time); // Cambia el tiempo en milisegundos según lo necesario

        return () => {
            clearInterval(interval);
        }
    }, [currentIndex, banners]);



    const handleBannerClick = async () => {
        try {
            const bannerId = banners[currentIndex]?.id;
            if (bannerId) {
                const clicks = await updateIncrementClicksBanner(bannerId);
                setBannerClicked(true);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleEntrarClick = async () => {
        try {
            if (!bannerClicked) {
                const bannerId = banners[currentIndex]?.id;
                if (bannerId) {
                    const clicks = await updateIncrementRejectBanner(bannerId);
                }
            }
            navigate('/login');
        } catch (error) {
            console.error('Error:', error);
        }
    };;

    const handleRegisterClick = async () => {
        try {
            if (!bannerClicked) {
                const bannerId = banners[currentIndex]?.id;
                if (bannerId) {
                    const clicks = await updateIncrementRejectBanner(bannerId);
                }
            }

            navigate('/register');

        } catch (error) {
            console.error('Error:', error);
        }
    }

    const [bannerClicked, setBannerClicked] = useState(false);

    const defaultColor = process.env.REACT_APP_DEFAULT_COLOR || '#ffffff';
    const primaryColor = process.env.REACT_APP_PRIMARY_COLOR || defaultColor;
    const primaryColorHover = process.env.REACT_APP_PRIMARY_COLOR_HOVER || defaultColor;

    return (
        <>
            {splash && !video && (defaultImage || bannersSplash[currentIndex]?.file_url) && (
                <a href={bannersSplash[currentIndex]?.redirect_url} target="_blank" onClick={handleBannerClick} style={{ backgroundColor: 'white', height: `${isMobile ? 'auto' : '100vh'}`, width: '100%' }}>
                    <img
                        style={{
                            backgroundColor: 'white',
                            height: `${isMobile ? 'auto' : '100vh'}`,
                            width: "100%",
                            backgroundSize: "contain",
                            objectFit: "contain",
                        }}
                        src={bannersSplash[currentIndex]?.file_url ? `${bannersSplash[currentIndex].file_url}` : (`data:image/jpeg;base64,${defaultImage}`)}
                        alt="logo"
                    />
                </a>
            )}

            {splash && video && (
                <a href={banners[currentIndex]?.redirect_url} target="_blank" onClick={handleBannerClick} style={{ backgroundColor: 'white', height: `${isMobile ? 'auto' : '100vh'}`, width: '100%' }}>
                    <video loop>
                        <source src={banners[currentIndex]?.file_url} type="video/mp4" style={{
                            backgroundSize: "contain",
                        }} />
                        Tu navegador no admite la reproducción de videos.
                    </video>
                </a>
            )}

            {!splash && !video && (defaultImage || banners[currentIndex]?.file_url) && (
                <a href={banners[currentIndex]?.redirect_url} target="_blank" onClick={handleBannerClick} style={{ backgroundColor: 'white', height: `${isMobile ? 'auto' : '100vh'}`, width: '100%' }}>
                    <img
                        style={{
                            backgroundColor: 'white',
                            height: `${isMobile ? 'auto' : '100vh'}`,
                            width: "100%",
                            backgroundSize: "contain",
                            objectFit: "contain",
                        }}
                        src={banners[currentIndex]?.file_url ? `${banners[currentIndex].file_url}` : (`data:image/jpeg;base64,${defaultImage}`)}
                        alt="logo"
                    />
                </a>
            )}
            {
                !splash && video && (
                    <a href={banners[currentIndex]?.redirect_url} target="_blank" onClick={handleBannerClick} style={{ backgroundColor: 'white', height: `${isMobile ? 'auto' : '100vh'}`, width: '100%' }}>
                        <video loop>
                            <source src={banners[currentIndex]?.file_url} type="video/mp4" style={{
                                backgroundSize: "contain",
                            }} />
                            Tu navegador no admite la reproducción de videos.
                        </video>
                    </a>
                )
            }
            {

                !splash && !isLoading &&
                <Container component="main" style={{ height: `${isMobile ? 'auto' : '100vh'}`, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: primaryColor }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            color: 'white',
                        }}
                    >
                        <WifiIcon sx={{ fontSize: `${isMobile ? '2rem' : '10rem'}`, marginY: '1rem', marginBottom: "30px" }} />
                        <Typography component="h1" variant="h3" sx={{ textAlign: 'center' }}>
                            {title}
                        </Typography>
                        <Typography component="h2" variant="h5" sx={{ textAlign: 'center', marginBottom: "10px" }}>
                            {description}
                        </Typography>

                        <NavLink to="/login">
                            <Button
                                type="submit"
                                variant="contained"
                                size="large"
                                sx={{
                                    my: 3,
                                    py: '1rem',
                                    px: '3rem',
                                    borderRadius: '2rem',
                                    color: primaryColor,
                                    fontSize: '1.2rem',
                                    marginBottom: "5px",
                                    fontWeight: '800',
                                    '&:hover': {
                                        backgroundColor: 'white'
                                    }
                                }}
                                disableElevation
                                onClick={() => {
                                    handleSnackbarLoginClick();
                                    handleEntrarClick();
                                }}
                            >
                                <FormattedMessage id="get_in_btn" />
                            </Button>
                        </NavLink>
                        {activeRegister && <NavLink to={autoRegisterEnabled ? "/register" : ""}>
                            <Button
                                type="submit"
                                variant="contained"
                                size="large"
                                sx={{
                                    my: 3,
                                    py: '1rem',
                                    px: '3rem',
                                    borderRadius: '2rem',
                                    color: primaryColor,
                                    fontSize: '1.2rem',
                                    fontWeight: '800',
                                    '&:hover': {
                                        backgroundColor: 'white'
                                    }
                                }}
                                disableElevation
                                disabled={!autoRegisterEnabled}

                                onClick={() => {
                                    handleSnackbarRegisterClick();
                                    handleRegisterClick();
                                }}
                            >
                                <FormattedMessage id="register" />
                            </Button>
                        </NavLink>}
                        <LanguageSelector selectedLanguage={selectedLanguage} onLanguageChange={handleLanguageChange} />
                    </Box>
                </Container >
            }
            <FloatingImageWhite />
        </>

    );

}

export default PreRegistro;