
/**
 * @returns {boolean} El resultado de la función
 */
const conectApp = async (email,password) => {
    // Se setea la pass porque no se pide
    return connectUser(email, password);
  };
  
  /**
   * @returns {boolean} El resultado de la función
   */
  async function connectUser(email,password) {
    
    await window.connect(email, password);
  
    setTimeout(2500, function () {
        if (window.chilliController.clientState === 1) {
            console.log("Estado de conexión a CoovaChilli: " + window.chilliController.clientState);
            return true;
        } else if (window.chilliController.clientState === 2) {
            console.log("Estado de conexión a CoovaChilli: " + window.chilliController.clientState);
            return false;
        }
        window.chilliController.refresh();
    });
  }
    
  export default conectApp;
