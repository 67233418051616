import axios from "axios"
import { API_PORTAL_URL } from "../constants/api"
import { ILogInBody } from "../interfaces/ILogInBody";

export const loginWithEmail = async ({ email, password, method }: ILogInBody) => {
    try {

        const headers = {
            "X-Portal-Secret": 'SecureSecretPaswwordPortalCaptive01',
        };

        let body = {};
        if (password !== '') {
            body = {
                email,
                password,
                method
            }
        } else {
            body = {
                email,
                method
            }
        }


       const loginInfo = await axios.post(`${API_PORTAL_URL}/login`, body, { headers });
         if (loginInfo) {
            return true;
        } else {
            return false;
        }
    } catch (err) {
        console.error(err);
        return false;
    }
}

export const register = async ({ email, name, password }: ILogInBody) => {
    try {
        const registerInfo = await axios.post(`${API_PORTAL_URL}/register`, {
            email,
            name,
            password
        });
        if (registerInfo) {
            return registerInfo;
        }
    } catch (err) {
        console.error(err);
        return false;
    }
}


export const loginDefault = async () => {
    try {
        const defaultLogin = await axios.post(`${API_PORTAL_URL}/logindefaultaccount`, {});
        if (defaultLogin) {
            return defaultLogin;
        }
    } catch (err) {
        console.error(err);
        return false;
    }
}
