import { Container, Box, TextField, Button, styled, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import WifiIcon from '@mui/icons-material/Wifi';
import GoogleIcon from '@mui/icons-material/Google';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import { FormattedMessage } from 'react-intl';
import { register } from '../../../../services/user';
import { useFormik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { API_PORTAL_URL } from '../../../../constants/api';
import { useNavigate } from 'react-router-dom';
import LanguageSelector from '../pre-registro/LanguageSelector';
import { GetAllSettings, GetSelectedBanner } from '../../../../services';
import { updateIncrementClicksBanner, updateIncrementRejectBanner } from '../../../../services';
import './styles.css';
import FloatingImageWhite from '../../../FloatingImageWhite';

const LogInSchema = Yup.object().shape({
    email: Yup.string().email().required(),
    emailRepeat: Yup.string().email().required(),
    name: Yup.string()
        .required()
        .min(1),
    password: Yup.string().required(),
    passwordRepeat: Yup.string().required(),
});

const TextInput = styled(TextField)(() => ({
    '& fieldset': {
        borderRadius: '1.25rem',
        borderColor: 'white',
        '& legend': {
            '& span': {
                color: 'white'
            }
        }
    },
    '@media (max-width: 768px)': {
        width: '100%',
    },
    '@media (max-width: 480px)': {
        width: '100%',
    },
}));

interface ILogInForm {
    email: string;
    name: string;
    emailRepeat: string;
    password: string;
    passwordRepeat: string;
}

interface ILogIn {
    isMobile?: boolean;
    currentLocale: string;
    setLocale: (locale: string) => void;
    title: string;
    changeLenguage: (locale: string) => void;
    banners: any[];
}

const Register = ({ isMobile, currentLocale, setLocale, title, changeLenguage, banners }: ILogIn) => {

    const [selectedLanguage, setSelectedLanguage] = useState<string>('');
    const [defaultImage, setDefaultImage] = useState<string>('');
    const [video, setVideo] = useState<boolean>(false);

    const [activeFacebook, setActiveFacebook] = useState<boolean>(false);
    const [activeLinkedin, setActiveLinkedin] = useState<boolean>(false);
    const [activeGoogle, setActiveGoogle] = useState<boolean>(false);

    const [activeEmail, setActiveEmail] = useState<boolean>(true);
    const [activePassword, setActivePassword] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [error, setError] = useState('');

    const handleLanguageChange = (language: string) => {
        changeLenguage(language);
        setSelectedLanguage(language);
        setLocale(language);
    };

    const navigate = useNavigate();
    const handleSubmit = async (values: ILogInForm, formikHelpers: FormikHelpers<any>) => {
        if (values.email != values.emailRepeat) {
            setError('Los correos electrónicos no coinciden.');
        }
        if (values.password != values.passwordRepeat) {
            setError('Las contraseñas no coinciden.');
        }
        if (!(values.email != values.emailRepeat || values.password != values.passwordRepeat)) {
            const resRegister = await register({ email: values.email, name: values.name, password: values.password });
            navigate(resRegister ? '/success' : '/fail');
        }
    };

    const handleGoogleLogin = () => {
        window.location.href = `${API_PORTAL_URL}/google/login`;
    }

    const handleFacebookLogin = () => {
        window.location.href = `${API_PORTAL_URL}/facebook/login`;
    }

    const handleLinkedInLogin = () => {
        window.location.href = `${API_PORTAL_URL}/linkedin/login`;
    }

    const onChange = (event: React.ChangeEvent<any>) => {
        event.persist();
        formik.handleChange(event);
        formik.setFieldTouched(event.target.name, true, false);
    };

    const [currentIndex, setCurrentIndex] = useState<number>(0);


    useEffect(() => {
        let time = 5000;
        if (banners && banners.length > 0) {
            time = banners[currentIndex].weight ? banners[currentIndex].weight * 100 : 5000;
        }
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % banners.length);
        }, time); // Cambia el tiempo en milisegundos según lo necesario

        return () => {
            clearInterval(interval);
        }
    }, [currentIndex, banners]);

    useEffect(() => {
        if (!banners || banners.length == 0) {
            const getAll = async () => {
                let res = await GetSelectedBanner();
                if (res && res.pre_registro) {
                    if (res.banner_type != "imagen") {
                        setVideo(true);
                    }
                    banners.push(res);
                }
            }
            getAll();
        } else {
            if (banners[currentIndex].banner_type != "imagen") {
                setVideo(true);
            }
        }

        const getSettings = async () => {
            const settings: any[] = await GetAllSettings();
            if (settings && settings.length > 0) {
                if (settings[0].file_encoded) {
                    setDefaultImage(settings[0].file_encoded);
                }
                if (settings[0].social && settings[0].social.length > 0) {
                    settings[0].social.forEach((social: { name: string; is_active: any; }) => {
                        if (social.name === 'facebook' && social.is_active) {
                            setActiveFacebook(true);
                        }

                        if (social.name === 'linkedin' && social.is_active) {
                            setActiveLinkedin(true);
                        }

                        if (social.name === 'google' && social.is_active) {
                            setActiveGoogle(true);
                        }
                    });
                }

                if (settings[0].form_login) {

                    if (settings[0].invite_mode || !settings[0].form_login.password) {
                        return window.location.href = '/login';
                    }

                    if (!settings[0].form_login.email) {
                        setActiveEmail(false);
                    }
                    if (!settings[0].form_login.password) {
                        setActivePassword(false);
                    }
                }
            }
            setIsLoading(false);
        };
        getSettings();

    }, [banners]);

    const formik = useFormik(
        {
            initialValues: {
                email: '',
                emailRepeat: '',
                name: '',
                password: '',
                passwordRepeat: '',
                acceptsTermsConditions: false
            },
            validationSchema: LogInSchema,
            onSubmit: handleSubmit
        }
    );

   
    const handleBannerClick = async () => {
        try {
            const bannerId = banners[currentIndex]?.id;
            if (bannerId) {
                const clicks = await updateIncrementClicksBanner(bannerId);
                setBannerClicked(true);
            }
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleEntrarClick = async () => {
        try {
            if (!bannerClicked) {
                const bannerId = banners[currentIndex]?.id;
                if (bannerId) {
                    const clicks = await updateIncrementRejectBanner(bannerId);
                }
            }
            handleSubmit(formik.values, formik);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const [bannerClicked, setBannerClicked] = useState(false);

    const defaultColor = process.env.REACT_APP_DEFAULT_COLOR || '#ffffff';
    const primaryColor = process.env.REACT_APP_PRIMARY_COLOR || defaultColor;
    const primaryColorHover = process.env.REACT_APP_PRIMARY_COLOR_HOVER || defaultColor;

    return (
        <>
            {video && (
                <a href={banners[currentIndex]?.redirect_url}
                    target="_blank"
                    onClick={handleBannerClick}
                    style={{ backgroundColor: 'white', height: `${isMobile ? 'auto' : '100vh'}`, width: '100%' }}>
                    <video loop>
                        <source src={banners[currentIndex]?.file_url} type="video/mp4" style={{
                            backgroundSize: "contain",
                        }} />
                        Tu navegador no admite la reproducción de videos.
                    </video>
                </a>
            )}
            {!video && (defaultImage || banners[currentIndex]?.file_url) && (
                <a href={banners[currentIndex]?.redirect_url}
                    target="_blank"
                    onClick={handleBannerClick}
                    style={{ backgroundColor: 'white', height: `${isMobile ? 'auto' : '100vh'}`, width: '100%' }}
                >
                    <img
                        style={{
                            backgroundColor: 'white',
                            height: `${isMobile ? 'auto' : '100vh'}`,
                            width: "100%",
                            backgroundSize: "contain",
                            objectFit: "contain",
                        }}
                        src={banners[currentIndex]?.file_url ? `${banners[currentIndex].file_url}` : (`data:image/jpeg;base64,${defaultImage}`)}
                        alt="logo"
                    />
                </a>
            )}
            {!isLoading &&
                <Container component="main" style={{ height: `${isMobile ? 'auto' : '100vh'}`, display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: primaryColor }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            color: 'white'
                        }}
                    >
                        <WifiIcon sx={{ fontSize: `${isMobile ? '1rem' : '10rem'}`, marginY: '-1rem' }} />
                        <form
                            onSubmit={formik.handleSubmit}
                            style={{
                                marginTop: 2,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <TextInput
                                margin="normal"
                                required
                                value={formik.values.name}
                                onChange={onChange}
                                sx={{ width: '30rem', borderRadius: '1rem', color: 'white' }}
                                name="name"
                                label={<FormattedMessage id="name_label" />}
                                type="name"
                                id="name"
                                autoComplete="off"
                                InputProps={{
                                    style: { color: '#fff' }
                                }}
                                InputLabelProps={{
                                    style: { color: '#fff' },
                                }}
                            />
                            {activeEmail && <TextInput
                                margin="normal"
                                required
                                type="email"
                                value={formik.values.email}
                                onChange={onChange}
                                sx={{ width: '30rem', borderRadius: '1rem', color: 'white' }}
                                id="email"
                                label={<FormattedMessage id="email_label" />}
                                name="email"
                                autoComplete="off"
                                InputProps={{
                                    style: { color: '#fff' }
                                }}
                                InputLabelProps={{
                                    style: { color: '#fff' },
                                }}
                            />}
                            {activeEmail && <TextInput
                                margin="normal"
                                required
                                type="emailRepeat"
                                value={formik.values.emailRepeat}
                                onChange={onChange}
                                sx={{ width: '30rem', borderRadius: '1rem', color: 'white' }}
                                id="emailRepeat"
                                label={<FormattedMessage id="email_repeat_label" />}
                                name="emailRepeat"
                                autoComplete="off"
                                InputProps={{
                                    style: { color: '#fff' }
                                }}
                                InputLabelProps={{
                                    style: { color: '#fff' },
                                }}
                            />}
                            {activePassword && <TextInput
                                margin="normal"
                                required
                                type="password"
                                value={formik.values.password}
                                onChange={onChange}
                                sx={{ width: '30rem', borderRadius: '1rem', color: 'white' }}
                                id="password"
                                label={<FormattedMessage id="password" />}
                                name="password"
                                autoComplete="off"
                                InputProps={{
                                    style: { color: '#fff' }
                                }}
                                InputLabelProps={{
                                    style: { color: '#fff' },
                                }}
                            />}
                            {activePassword && <TextInput
                                margin="normal"
                                required
                                type="password"
                                value={formik.values.passwordRepeat}
                                onChange={onChange}
                                sx={{ width: '30rem', borderRadius: '1rem', color: 'white' }}
                                id="passwordRepeat"
                                label={<FormattedMessage id="password_repeat" />}
                                name="passwordRepeat"
                                autoComplete="off"
                                InputProps={{
                                    style: { color: '#fff' }
                                }}
                                InputLabelProps={{
                                    style: { color: '#fff' },
                                }}
                            />}
                            <Box>
                                {activeGoogle && <IconButton onClick={handleGoogleLogin} size="large" color="secondary" sx={{ mx: '1rem', my: '1rem', marginBottom: '0px', marginTop: '10px', backgroundColor: 'white', boxShadow: '0px 0px 5px 1px rgba(0, 0, 0, 0.15)', '&:hover': { backgroundColor: 'white', border: '1px solid black' } }}>
                                    <GoogleIcon fontSize="inherit" />
                                </IconButton>}
                                {activeLinkedin && <IconButton onClick={handleLinkedInLogin} size="large" color="secondary" sx={{ mx: '1rem', my: '1rem', marginBottom: '0px', marginTop: '10px', backgroundColor: 'white', boxShadow: '0px 0px 5px 1px rgba(0, 0, 0, 0.15)', '&:hover': { backgroundColor: 'white', border: '1px solid black' } }}>
                                    <LinkedInIcon fontSize="inherit" />
                                </IconButton>}
                                {activeFacebook && <IconButton onClick={handleFacebookLogin} size="large" color="secondary" sx={{ mx: '1rem', my: '1rem', marginBottom: '0px', marginTop: '10px', backgroundColor: 'white', boxShadow: '0px 0px 5px 1px rgba(0, 0, 0, 0.15)', '&:hover': { backgroundColor: 'white', border: '1px solid black' } }}>
                                    <FacebookRoundedIcon fontSize="inherit" />
                                </IconButton>}
                            </Box>
                            <Button
                                type="button"
                                onClick={handleEntrarClick}
                                variant="contained"
                                size="large"
                                sx={{
                                    my: 3,
                                    py: '1rem',
                                    px: '3rem',
                                    borderRadius: '2rem',
                                    color: primaryColor,
                                    fontSize: '1.2rem',
                                    marginBottom: "8px",
                                    marginTop: "20px",
                                    fontWeight: '800',
                                    '&:hover': {
                                        backgroundColor: 'white'
                                    }
                                }}
                                disableElevation
                            >
                                <FormattedMessage id="register" />

                            </Button>

                        </form>
                        {error && <div className="error-message">{error}</div>}
                        <LanguageSelector selectedLanguage={selectedLanguage} onLanguageChange={handleLanguageChange} />
                    </Box>
                </Container>
            }
            <FloatingImageWhite />
        </>
    );
}

export default Register;