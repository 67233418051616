import React, { useState } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';


interface ILanguageSelector {
    selectedLanguage: string;
    onLanguageChange: (language: string) => void;
}

const LanguageSelector: React.FC<ILanguageSelector> = ({ selectedLanguage, onLanguageChange }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);


    const handleLanguageClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleLanguageClose = () => {
        setAnchorEl(null);
    };

    const handleLanguageChange = (language: string) => {
        onLanguageChange(language);
        handleLanguageClose();
    };

    const languageOptions = [
        { code: 'es-es', label: 'Español' },
        { code: 'en-us', label: 'English' },
        { code: 'pt-pt', label: 'Português' },
    ];



    const selectedLanguageLabel = languageOptions.find(option => option.code === selectedLanguage)?.label || 'Language';

    const defaultColor = process.env.REACT_APP_DEFAULT_COLOR || '#ffffff';
    const primaryColor = process.env.REACT_APP_PRIMARY_COLOR || defaultColor;
    const primaryColorHover = process.env.REACT_APP_PRIMARY_COLOR_HOVER || defaultColor;

    const customMenuStyles: React.CSSProperties = {
        backgroundColor: primaryColor,
        border: '1px solid #ccc',
        color: 'white'
    };



    return (
        <div>
            <Button
                aria-controls="language-menu"
                aria-haspopup="true"
                onClick={handleLanguageClick}
                startIcon={<LanguageIcon />}
            >
                {selectedLanguageLabel}
            </Button>
            <Menu
                id="language-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleLanguageClose}
                PaperProps={{
                    style: customMenuStyles,
                }}
            >
                {languageOptions.map(option => (
                    <MenuItem key={option.code} onClick={() => handleLanguageChange(option.code)}>
                        {option.label}
                    </MenuItem>
                ))}
            </Menu>

        </div>
    );
};

export default LanguageSelector;

